import React from 'react';

export default class BlogPage extends React.Component {
  render() {
    return (
      <div id="BlogPage" className="page">
        <p>Coming soon!</p>
      </div>
    );
  }
}